const ruVocabulary = {
  'Change Password': 'Поменять пароль',
  'Creating Account': 'Cоздание учетной записи',
  Email: 'Электронная почта',
  'Enter your code': 'Введите код',
  'Enter your Email': 'Введите электронную почту',
  "Enter your email": "Введите электронную почту",
  'Enter your phone number': 'Введите номер телефона',
  'Enter your username': 'Введите имя пользователя',
  'Forgot your password?': 'Забыли пароль?',
  'Hide password': 'Скрыть пароль',
  'Resend Code': 'Отправить код еще раз',
  'Reset your password': 'Поменять пароль',
  'Reset your Password': 'Поменять пароль',
  'Reset Password': "Поменять пароль",
  'Send code': 'Отправить Код',
  'Send Code': 'Отправить Код',
  Sending: 'отправляем',
  'Setup TOTP': 'Настраивать TOTP',
  'Show password': 'Показать пароль',
  'Sign in to your account': 'Вход',
  'Sign In with Amazon': 'Войти через Amazon',
  'Sign In with Apple': 'Войти через Apple',
  'Sign In with Facebook': 'Войти через Facebook',
  'Sign In with Google': 'Войти через Google',
  'Sign in': 'Вход',
  'Sign In': 'Вход',
  'Signing in': 'Входим',
  Skip: 'Пропустить',
  Submit: 'Отослать',
  Submitting: 'Отсылаем',
};

export default ruVocabulary;