import { Container } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";


const NoPage = () => {
    const { t } = useTranslation();
    return (
        <Container sx={{display: "flex", flexGrow: 1}}>
            <p>{t("noPage.title")}</p>
        </Container>
    )
};

export default NoPage;