
import { Avatar } from "@mui/material";
import React from "react";
import { userContext } from ".";

const UserAvatar = ({size=32}) => {
    return (
        <userContext.Consumer >
            {({ picture }) => (
                <Avatar sx={{ width: size, height: size }} src={picture} alt="user avatar"/>
            )}
        </userContext.Consumer >
    );
};

export default UserAvatar;
