import LNG_MAP from 'i18n/languages';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Config } from 'services/Config';

const SEO = ({ title, description, path, name = null, type = 'website' }) => {
  const { t, i18n } = useTranslation();

  return (
    <Helmet>
      { /* Standard metadata tags */}
      <title>{title}</title>
      <meta name='description' content={description} />
      { /* End standard metadata tags */}

      { /* Facebook tags */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      { /* End Facebook tags */}

      { /* Twitter tags */}
      <meta name="twitter:creator" content={name || t('global.fullName')} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      { /* End Twitter tags */}

      {
        Object.keys(LNG_MAP).map((code) => {
          return (
            <link key={code} rel="alternate" href={`${Config.getBaseUrl()}/${code}/${path}`} hreflang={code} />
          )
        })
      }
      <link rel="canonical" href={`${Config.getBaseUrl()}/${i18n.language || 'en'}/${path}`} />
    </Helmet>
  )
}

export default SEO;