// is used in csl_devops' i18n script
// i18n script reads json from `LANGUAGES_REGISTRY` to `;` char
const LANGUAGES_REGISTRY = {
  "de": "Deutsch",
  "en": "English",
  "es": "Español",
  "fr": "Français",
  "hi": "हिन्दी",
  "id": "Bahasa Indonesia",
  "it": "Italiano",
  "ja": "日本語",
  "ko": "한국어",
  "nl": "Nederlands",
  "no": "Norsk",
  "pl": "Polski",
  "pt": "Português",
  "ru": "Русский",
  "sv": "Svenska",
  "th": "ไทย",
  "tr": "Türkçe",
  "zh": "中文"
};


// sorted map
const LNG_MAP = Object.keys(LANGUAGES_REGISTRY).sort().reduce(
  (obj, key) => {
    obj[key] = LANGUAGES_REGISTRY[key];
    return obj;
  },
  {}
);

export default LNG_MAP;