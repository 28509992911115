import { Container, Typography } from "@mui/material";
import React, { useContext } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { userContext } from "Components/User";
import { useTranslation } from "react-i18next";
import utils from "utils";

export const SubscriptionCancelled = () => {

  const uContext = useContext(userContext);
  const { t } = useTranslation();

  return (
    <Container sx={{ display: "flex", flexGrow: "1", justifyContent: "center" }}>
      <Grid container flexGrow={1} justifyContent={"center"} mt={1} mb={1} spacing={2}>
        <Grid xs={12} sm={8} >
          <Typography variant="h6" textAlign={"center"} mt={2}>
            {t("subscription.cancelledHeader")}
          </Typography >
          <Typography mt={2}>
            {t("subscription.receivedAndProcessed")}
          </Typography>
          <Typography mt={3}>
            {t("subscription.sad")}
          </Typography>

          <Typography fontWeight={"bold"} mt={3}>
            {t("subscription.next")}
          </Typography>
          <ul>
            <li>
              <Typography sx={{ fontWeight: "bold" }}>
                {t("subscription.accessUntil", { expiresAt: utils.toLocaleDateString(uContext.subscription?.expires_at) })}
              </Typography>
              <Typography>{t("subscription.serviceRemain", { expiresAt: utils.toLocaleDateString(uContext.subscription?.expires_at) })}</Typography>
            </li>
            <li>
              <Typography sx={{ fontWeight: "bold" }}>{t("subscription.noMoreBillingHeader")}</Typography>
              <Typography>{t("subscription.noMoreBilling")}</Typography>
            </li>
            <li>
              <Typography sx={{ fontWeight: "bold" }}>{t("subscription.comeBackHeader")}</Typography>
              <Typography>{t("subscription.comeBack")}</Typography>
            </li>
          </ul>

          <Typography sx={{ fontWeight: "bold" }} mt={3}>{t("subscription.feedbackMattersHeader")}</Typography>
          <Typography>
            {t("subscription.feedbackMatters", { "email": utils.CSL_EMAIL })}
          </Typography>

          <Typography sx={{ fontWeight: "bold" }} mt={3}>
            {t("subscription.needHelpHeader")}
          </Typography>
          <Typography>
            {t("subscription.needHelp", { "email": utils.CSL_EMAIL })}
          </Typography>

          <Typography mt={3}>
            {t("subscription.thanks")}
          </Typography>
          <Typography mt={3}>
            {t("subscription.regards")}
          </Typography>
          <Typography >
            {t("subscription.team")}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
