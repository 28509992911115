import { Config } from "services/Config";
import { get } from "services/Helper";

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export class SearchService {
    static getVideos = (searchQuery, nextPageToken = null) => {
        const params = {
            "query": encodeURIComponent(searchQuery)
        };
        if (!!nextPageToken) {
            params["nextPageToken"] = nextPageToken;
        }
        return get(Config.searchUrl(), params);

    //     return sleep(300).then(() => {
    //         if (!nextPageToken) {
    //             return {
    //                 "items": [
    //                     {
    //                         "id": "ykX72tpo2EI",
    //                         "title": "WATCH LIVE Lexus Pipe Pro presented by YETI 2024 - Day 4",
    //                         "channel_id": "UChuLeaTGRcfzo0UjL-2qSbQ",
    //                         "player": null,
    //                         "thumbnail": {
    //                             "url": "https://i.ytimg.com/vi/ykX72tpo2EI/mqdefault.jpg",
    //                             "width": 320,
    //                             "height": 180
    //                         }
    //                     },
    //                     {
    //                         "id": "AEYupHAEdr8",
    //                         "title": "Mason Ho, Noa Deane, Sheldon Paishon &amp; Harry Bryant Hawaiian Field Trip",
    //                         "channel_id": "UCeYue9Nbodzg3T1Nt88E3fg",
    //                         "player": null,
    //                         "thumbnail": {
    //                             "url": "https://i.ytimg.com/vi/AEYupHAEdr8/mqdefault.jpg",
    //                             "width": 320,
    //                             "height": 180
    //                         }
    //                     },
    //                     {
    //                         "id": "njCrxFgFO-A",
    //                         "title": "A Fresh Swell and a Little Drop In Karma (Raw Surfing)",
    //                         "channel_id": "UC3DIR1s_jw1wr4p4wWg7JGQ",
    //                         "player": null,
    //                         "thumbnail": {
    //                             "url": "https://i.ytimg.com/vi/njCrxFgFO-A/mqdefault.jpg",
    //                             "width": 320,
    //                             "height": 180
    //                         }
    //                     },
    //                     {
    //                         "id": "eSWST13stO4",
    //                         "title": "\ud83d\udd354k (ASMR) 10 Hour Store Loop - Hawaii Surfing - With Relaxing Music\u2611\ufe0f",
    //                         "channel_id": "UCVo06dBGK9VBBhq15wJxZHQ",
    //                         "player": null,
    //                         "thumbnail": {
    //                             "url": "https://i.ytimg.com/vi/eSWST13stO4/mqdefault.jpg",
    //                             "width": 320,
    //                             "height": 180
    //                         }
    //                     },
    //                     {
    //                         "id": "ma67yOdMQfs",
    //                         "title": "These Were The All-Time Surfing Moments Of The Year | Best Of 2020",
    //                         "channel_id": "UC--3c8RqSfAqYBdDjIG3UNA",
    //                         "player": null,
    //                         "thumbnail": {
    //                             "url": "https://i.ytimg.com/vi/ma67yOdMQfs/mqdefault.jpg",
    //                             "width": 320,
    //                             "height": 180
    //                         }
    //                     },
    //                     {
    //                         "id": "FDP_KA2R_ek",
    //                         "title": "WATCH LIVE Lexus Pipe Pro presented by YETI 2024 - Day 1",
    //                         "channel_id": "UChuLeaTGRcfzo0UjL-2qSbQ",
    //                         "player": null,
    //                         "thumbnail": {
    //                             "url": "https://i.ytimg.com/vi/FDP_KA2R_ek/mqdefault.jpg",
    //                             "width": 320,
    //                             "height": 180
    //                         }
    //                     },
    //                     {
    //                         "id": "bImk2wEVVCc",
    //                         "title": "\ud83d\udd34(ASMR) Teahupo&#39;o: The Ultimate Surfing Experience - August 2023",
    //                         "channel_id": "UCVo06dBGK9VBBhq15wJxZHQ",
    //                         "player": null,
    //                         "thumbnail": {
    //                             "url": "https://i.ytimg.com/vi/bImk2wEVVCc/mqdefault.jpg",
    //                             "width": 320,
    //                             "height": 180
    //                         }
    //                     },
    //                     {
    //                         "id": "xtHZKToMvus",
    //                         "title": "Interlusion | A Billabong Surf Film Shot in the Mentawai Islands",
    //                         "channel_id": "UCTYHNSWYy4jCSCj1Q1Fq0ew",
    //                         "player": null,
    //                         "thumbnail": {
    //                             "url": "https://i.ytimg.com/vi/xtHZKToMvus/mqdefault.jpg",
    //                             "width": 320,
    //                             "height": 180
    //                         }
    //                     },
    //                     {
    //                         "id": "eWKucDh3Z1k",
    //                         "title": "WATCH LIVE Lexus Pipe Pro presented by YETI 2024 - Day 2",
    //                         "channel_id": "UChuLeaTGRcfzo0UjL-2qSbQ",
    //                         "player": null,
    //                         "thumbnail": {
    //                             "url": "https://i.ytimg.com/vi/eWKucDh3Z1k/mqdefault.jpg",
    //                             "width": 320,
    //                             "height": 180
    //                         }
    //                     },
    //                     {
    //                         "id": "5qsHU88dwuI",
    //                         "title": "Surfing with his son on his back!\u2764\ufe0f\ud83d\ude32",
    //                         "channel_id": "UCc-oOp182_mo5TicquwUVoA",
    //                         "player": null,
    //                         "thumbnail": {
    //                             "url": "https://i.ytimg.com/vi/5qsHU88dwuI/mqdefault.jpg",
    //                             "width": 320,
    //                             "height": 180
    //                         }
    //                     },
    //                     {
    //                         "id": "1c-4NxXvH-g",
    //                         "title": "Pro Surfer Goes Down Hard! #pipeline  #surfing",
    //                         "channel_id": "UCjIuqG1hCxUdLLxOD_uR0vQ",
    //                         "player": null,
    //                         "thumbnail": {
    //                             "url": "https://i.ytimg.com/vi/1c-4NxXvH-g/mqdefault.jpg",
    //                             "width": 320,
    //                             "height": 180
    //                         }
    //                     },
    //                     {
    //                         "id": "sW6wIes9VYU",
    //                         "title": "These Surfing Moments Are Wild",
    //                         "channel_id": "UCx18ywTcohtS9lpNXt8noWQ",
    //                         "player": null,
    //                         "thumbnail": {
    //                             "url": "https://i.ytimg.com/vi/sW6wIes9VYU/mqdefault.jpg",
    //                             "width": 320,
    //                             "height": 180
    //                         }
    //                     },
    //                 ],
    //                 "nextPageToken": "CBgQAA"
    //             }
    //         }
    //         if (nextPageToken === "CBgQAA") {
    //             return {
    //                 "items": [
    //                     {
    //                         "id": "yWsSlFdR-l0",
    //                         "title": "Sandbar Fun On The North Shore (4K Raw)",
    //                         "channel_id": "UCjIuqG1hCxUdLLxOD_uR0vQ",
    //                         "player": null,
    //                         "thumbnail": {
    //                             "url": "https://i.ytimg.com/vi/yWsSlFdR-l0/mqdefault.jpg",
    //                             "width": 320,
    //                             "height": 180
    //                         }
    //                     },
    //                     {
    //                         "id": "bJpa_60UlNE",
    //                         "title": "The 2023 Eddie Aikau Big Wave Invitational at Waimea Bay",
    //                         "channel_id": "UC4i3-yfVazfuqwoz71T79Sw",
    //                         "player": null,
    //                         "thumbnail": {
    //                             "url": "https://i.ytimg.com/vi/bJpa_60UlNE/mqdefault.jpg",
    //                             "width": 320,
    //                             "height": 180
    //                         }
    //                     },
    //                     {
    //                         "id": "zZqxPozl2Ec",
    //                         "title": "Surfing Massive Waves Waimea Bay (Jan 22, 2023)  4K",
    //                         "channel_id": "UCEUYW6zm6KnUxPcwY1D8HsQ",
    //                         "player": null,
    //                         "thumbnail": {
    //                             "url": "https://i.ytimg.com/vi/zZqxPozl2Ec/mqdefault.jpg",
    //                             "width": 320,
    //                             "height": 180
    //                         }
    //                     },
    //                     {
    //                         "id": "a48-c7kCdYI",
    //                         "title": "SURFING A GLASSY REEF BREAK! (4K POV)",
    //                         "channel_id": "UCoicMEw3njXscfwHlaAJVcQ",
    //                         "player": null,
    //                         "thumbnail": {
    //                             "url": "https://i.ytimg.com/vi/a48-c7kCdYI/mqdefault.jpg",
    //                             "width": 320,
    //                             "height": 180
    //                         }
    //                     },
    //                     {
    //                         "id": "iFEoxjcBREg",
    //                         "title": "WEIRDEST WAVES EVER?!",
    //                         "channel_id": "UCo_q6aOlvPH7M-j_XGWVgXg",
    //                         "player": null,
    //                         "thumbnail": {
    //                             "url": "https://i.ytimg.com/vi/iFEoxjcBREg/mqdefault.jpg",
    //                             "width": 320,
    //                             "height": 180
    //                         }
    //                     },
    //                     {
    //                         "id": "gT-0B44wZLo",
    //                         "title": "Crazy Pipe so HEAVY\ud83d\ude40Super Fun Watch [1/23/24] Surfing Pipeline North Shore Oahu Hawaii [4K]",
    //                         "channel_id": "UCDawiafJg2w0t1dVcI3e6Zw",
    //                         "player": null,
    //                         "thumbnail": {
    //                             "url": "https://i.ytimg.com/vi/gT-0B44wZLo/mqdefault.jpg",
    //                             "width": 320,
    //                             "height": 180
    //                         }
    //                     },
    //                     {
    //                         "id": "Q3kI8atcI6E",
    //                         "title": "The greatest surfer of all-time \ud83e\udd2f (via @AustinKeen)",
    //                         "channel_id": "UCx18ywTcohtS9lpNXt8noWQ",
    //                         "player": null,
    //                         "thumbnail": {
    //                             "url": "https://i.ytimg.com/vi/Q3kI8atcI6E/mqdefault.jpg",
    //                             "width": 320,
    //                             "height": 180
    //                         }
    //                     },
    //                     {
    //                         "id": "rj7xMBxd5iY",
    //                         "title": "BIG WAVE SURFING COMPILATION 2017",
    //                         "channel_id": "UCiiFGfvlKvX3uzMovO3unaw",
    //                         "player": null,
    //                         "thumbnail": {
    //                             "url": "https://i.ytimg.com/vi/rj7xMBxd5iY/mqdefault.jpg",
    //                             "width": 320,
    //                             "height": 180
    //                         }
    //                     },
    //                     {
    //                         "id": "QB8GMebrFXg",
    //                         "title": "Big wave surfers in Southern California #shorts",
    //                         "channel_id": "UCJBWZDkunrjWh50Etxdys2g",
    //                         "player": null,
    //                         "thumbnail": {
    //                             "url": "https://i.ytimg.com/vi/QB8GMebrFXg/mqdefault.jpg",
    //                             "width": 320,
    //                             "height": 180
    //                         }
    //                     },
    //                     {
    //                         "id": "SBIjvssOBrY",
    //                         "title": "WATCH LIVE Lexus Pipe Pro presented by YETI 2024 - Day 3",
    //                         "channel_id": "UChuLeaTGRcfzo0UjL-2qSbQ",
    //                         "player": null,
    //                         "thumbnail": {
    //                             "url": "https://i.ytimg.com/vi/SBIjvssOBrY/mqdefault.jpg",
    //                             "width": 320,
    //                             "height": 180
    //                         }
    //                     },
    //                     {
    //                         "id": "Iuy69BMHESM",
    //                         "title": "\ud83d\udd34(ASMR) Teahupoo: The Ultimate Surfing Experience - April 2023",
    //                         "channel_id": "UCVo06dBGK9VBBhq15wJxZHQ",
    //                         "player": null,
    //                         "thumbnail": {
    //                             "url": "https://i.ytimg.com/vi/Iuy69BMHESM/mqdefault.jpg",
    //                             "width": 320,
    //                             "height": 180
    //                         }
    //                     },
    //                     {
    //                         "id": "iZu_wM_CgBw",
    //                         "title": "(NEW FOR 2022) 10-Hour Store Loop - Surfing and Waves Hawaii/Tahiti",
    //                         "channel_id": "UCVo06dBGK9VBBhq15wJxZHQ",
    //                         "player": null,
    //                         "thumbnail": {
    //                             "url": "https://i.ytimg.com/vi/iZu_wM_CgBw/mqdefault.jpg",
    //                             "width": 320,
    //                             "height": 180
    //                         }
    //                     }
    //                 ],
    //                 "nextPageToken": "no more"
    //             }
    //         }
    //         if (nextPageToken === "no more") {
    //             return {
    //                 "items": []
    //             }
    //         }
    //     });
    }
}