import { List, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import TranslateIcon from '@mui/icons-material/Translate';
import LNG_MAP from 'i18n/languages';
import CheckIcon from '@mui/icons-material/Check';

const Language = () => {

  const navigate = useNavigate();
  const location = useLocation()
  const { i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (code) => {
    if (i18n.language !== code) {
      const oldLanguage = i18n.language;
      i18n.changeLanguage(code);
      let newPath = location.pathname
      if (newPath.includes("checkoutReturn") || newPath.includes("signin")) {
        // do nothing
      } else if (location.pathname.startsWith(`/${oldLanguage}`)) {
        newPath = newPath.replace(`/${oldLanguage}`, `/${code}`)
        navigate(newPath);  
      } else {
        newPath = `/${code}${newPath}`
        navigate(newPath);
      }
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <List
        sx={{ pr: ".5em" }}
        component="nav"
        aria-label="Available Languages"
        id="lngList"
      >
        <ListItemButton
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClickListItem}
        >
          <TranslateIcon sx={{ m: "3px" }} fontSize='small' />
          <ListItemText
            primary={(i18n.language || 'en').toUpperCase()}
          />
        </ListItemButton>
      </List>

      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
          style: {
            padding: 0
          }
        }}
      >
        {
          Object.keys(LNG_MAP).map(code => (
            <MenuItem
              key={code}
              selected={code === i18n.language}
              onClick={() => handleMenuItemClick(code)}
            >
              {code === i18n.language &&
                <ListItemIcon>
                  <CheckIcon fontSize='small' />
                </ListItemIcon>
              }
              <ListItemText>{LNG_MAP[code]}</ListItemText>

            </MenuItem>
          ))
        }
      </Menu>
    </>
  );
};

export default Language;