import { Config } from "services/Config";
import { get, post } from "services/Helper";


class PaymentService {
    static createCheckoutSession = (locale=null) => {
        return post(Config.createCheckoutSessionUrl(), {locale: locale});
    }

    static getReceipt = (sessionId) => {
        return get(Config.receiptsUrl(sessionId));
    }

    static getPayments = (startAfter) => {
        const params = {};
        if (startAfter) {
            params.starting_after = startAfter;
        }
        return get(Config.paymentsUrl(), params);
    }
}

export default PaymentService;
