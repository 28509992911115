import { Config } from "services/Config";
import { get, remove } from "services/Helper";


class SubscriptionService {
    static getSubsription = () => {
        // return new Promise(resolve => setTimeout(resolve, 2000)).then(() => {
        //     return {
        //         "user_name": "0238aefd-2705-4dd1-84fc-a62e94125439",
        //         "ai_token_credit": 102818,
        //         "created_at": "2024-02-03T04:50:04.310416+00:00",
        //         "expires_at": "2024-03-10T04:50:04.310442+00:00",
        //         "trial": true,
        //         "ai_prompts": null
        //     }
        // });
        return get(Config.getSubscriptionsUrl());
    };

    static cancelSubscription = () => {
        // return new Promise(resolve => setTimeout(resolve, 3000));
        return remove(Config.getSubscriptionsUrl());
    };
}

export default SubscriptionService;
