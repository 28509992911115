import { fetchAuthSession } from "aws-amplify/auth";

const getHeaders = (options) => {
    return fetchAuthSession().then(session => {
        options.headers = options.headers ?? {};
        options.headers.Authorization = session.tokens?.idToken?.toString();
        return options;
    }).catch((e) => {
        console.error("Can't get token", e);
    });
}

function request(
    url,
    params = {},
    method,
) {
    const options = {
        method: method,
        credentials: "omit"
    };
    if ("GET" === method && !!params && Object.keys(params).length > 0) {
        url += "?" + new URLSearchParams(params).toString();
    } else if ("POST" === method) {
        options.body = JSON.stringify(params);
    }
    // console.log(url, options);
    return getHeaders(options).then(options => {
        // console.log("auth options: ", options);
        return fetch(url, options)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                return Promise.reject(response);
            })
            .catch((err) => {
                console.error("catch request", err);
                return Promise.reject(err);
            });
    });
}

export function get(url, params) {
    return request(url, params, "GET");
}

export function post(url, body) {
    return request(url, body, "POST");
}

export function remove(url, params) {
    return request(url, params, "DELETE");
}
