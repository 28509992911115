import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import React, { lazy, Suspense, useEffect, useState } from 'react';
import Search from 'Components/Search';
import ErrorPage from 'Components/Error';
import NoPage from 'Components/NoPage';
import SignIn from 'Components/SignIn';
import Header from 'Components/Layout/Header';
import Footer from 'Components/Layout/Footer';
import { CircularProgress, Container, CssBaseline, Stack, ThemeProvider, createTheme, useMediaQuery } from '@mui/material';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { userContext } from 'Components/User';
import { fetchUserAttributes } from 'aws-amplify/auth';
import SubscriptionService from 'services/Subscription';
import CheckoutReturn from 'Components/Payment/CheckoutReturn';
import Settings from 'Components/Settings';
import { SubscriptionCancelled } from 'Components/Settings/SubscriptionCancelled';

import loadable from "@loadable/component";
import { PrerenderedComponent } from "react-prerendered-component";
import SEO from 'Components/Atoms/Seo';
import { useTranslation } from 'react-i18next';

const prerenderedLoadable = dynamicImport => {
  const LoadableComponent = loadable(dynamicImport);
  return React.memo(props => (
    <PrerenderedComponent live={LoadableComponent.load()}>
      <LoadableComponent {...props} />
    </PrerenderedComponent>
  ));
};

const Hero = prerenderedLoadable(() => import("./Components/Hero"));
const Pricing = prerenderedLoadable(() => import("./Components/Layout/Pricing"));
const Terms = prerenderedLoadable(() => import("./Components/Layout/Terms"));
const Privacy = prerenderedLoadable(() => import("./Components/Layout/Privacy"));
const CheckoutForm = lazy(() => import('./Components/Payment'));


function App() {
  const fonts = [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ];
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
    // && false
    ;

  const { i18n } = useTranslation();
  const [userState, setUserState] = useState({});

  const { user, signOut, authStatus } = useAuthenticator((context) => [context.user, context.signOut]);
  const { t } = useTranslation();

  useEffect(() => {
    if (!!user && authStatus === "authenticated") {

      const fetchUserPromise = fetchUserAttributes().catch((error) => error);
      const subscriptionPromise = SubscriptionService.getSubsription().catch((error) => error);

      Promise.all(
        [fetchUserPromise, subscriptionPromise]
      ).then(([userAttributes, subscription]) => {
        const newUser = {
          user: user,
          signOut: signOut,
          authStatus: authStatus
        };
        if (userAttributes instanceof Error) {
          console.error("user attributes error: ", userAttributes);
        } else {
          newUser.name = userAttributes.name ?? userAttributes.email;
          newUser.picture = userAttributes.picture;
        }

        if (subscription instanceof Error) {
          console.error("subscription error: ", subscription);
        } else {
          newUser.subscription = subscription;
          newUser.isTrial = !subscription || subscription.status === "trial";
        }
        setUserState(newUser);
      }).catch(err => {
        console.error("Oups...", err)
      });
    } else {
      setUserState({
        authStatus: authStatus
      });
    }
  }, [user, authStatus]);

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
          background: {
            default: prefersDarkMode ? "#000" : "#F1F2F3",
          },
          primary: {
            main: "#ff8c00",
          },
        },
        typography: {
          fontFamily: fonts.join(','),
        },
      }),
    [prefersDarkMode],
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <userContext.Provider value={userState}>
        <Container id="main-box" maxWidth="lg" sx={{ display: "flex", height: "100%", p: 0 }}>
          <Stack id="main-stack" style={{
            flexGrow: "1",
          }}>
            <BrowserRouter>
              <Header user={user} />
              <Routes>
                <Route path="/" element={<Navigate replace to={`${i18n.language || 'en'}`} />} />
                <Route path="/terms" element={<Navigate replace to={`/${i18n.language || 'en'}/terms`} />} />
                <Route path="/privacy" element={<Navigate replace to={`/${i18n.language || 'en'}/privacy`} />} />
                <Route path="/search" element={<Navigate replace to={`/${i18n.language || 'en'}/search`} />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/:lng"
                // element={<LanguageWrapper />}
                >
                  <Route path="" element={
                    <>
                      <SEO title={t("seo.hero.title")} description={t("seo.hero.description")} path={""}/>
                      <Hero />
                    </>
                  } />
                  <Route path="*" element={<NoPage />} />
                  <Route path="checkout" element={<Suspense fallback={<CircularProgress />}><CheckoutForm /> </Suspense>} />
                  <Route path="checkoutReturn" element={<CheckoutReturn />} />
                  <Route path="error" element={<ErrorPage />} />
                  <Route path="privacy" element={
                    <>
                      <SEO title={t("seo.privacy.title")} description={t("seo.privacy.description")} path={"privacy"}/>
                      <Privacy />
                    </>
                  } />
                  <Route path="search" element={<Search />} />
                  <Route path="settings" element={<Settings />} />
                  <Route path="subscription/cancelled" element={<SubscriptionCancelled />} />
                  <Route path="terms" element={
                    <>
                      <SEO title={t("seo.terms.title")} description={t("seo.terms.description")} path={"terms"}/>
                      <Terms />
                    </>
                  } />
                  <Route path="pricing" element={
                    <>
                      <SEO title={t("seo.pricing.title")} description={t("seo.pricing.description")} path={"pricing"} />
                      <Pricing />
                    </>
                  } />
                  {/* rect-snap need this to fix flaching page problem */}
                  <Route path="shell.html" element={null} />
                </Route>
              </Routes>
              <Footer />
            </BrowserRouter>
          </Stack>
        </Container>
      </userContext.Provider>
    </ThemeProvider>
  );
}

export default App;
