import { defaultDarkModeOverride } from '@aws-amplify/ui-react';

export const getTheme = (native_theme) => {
    const defaultBackgroud = native_theme.palette.background.default;
    const mainColor = native_theme.palette.primary.main;
    const mainDarkColor = native_theme.palette.primary.dark;
    const hover = native_theme.palette.action.hover;
    const theme = {
        name: 'my-theme',
        overrides: [
            defaultDarkModeOverride,
            {
                colorMode: native_theme?.palette.mode,
                tokens: {
                    colors: {
                        background: {
                            primary: { value: native_theme.palette.background.default },
                        },
                    },
                    components: {
                        button: {
                            backgroundColor: defaultBackgroud,
                            color: mainColor,
                            _active: {
                                backgroundColor: hover,
                            },
                            _hover: {
                                backgroundColor: hover,
                                borderColor: mainColor,
                            },
                            link: {
                                color: mainColor,
                                _hover: {
                                    color: mainDarkColor,
                                    backgroundColor: hover,
                                },
                                _focus: {
                                    backgroundColor: hover,
                                },
                                _active: {
                                    backgroundColor: hover,
                                }
                            },
                            primary: {
                                backgroundColor: mainColor,
                                borderColor: mainColor,
                                _hover: {
                                    backgroundColor: mainDarkColor,
                                },
                                _active: {
                                    backgroundColor: mainDarkColor,
                                },
                                _focus: {
                                    backgroundColor: mainDarkColor,
                                }
                            }
                        },
                        field: {
                            label: {
                                color: native_theme.palette.text.primary
                            }
                        },
                        input: {
                            backgroundColor: defaultBackgroud,
                        },
                        tabs: {
                            // borderColor: "red",
                            item: {
                                color: native_theme.palette.text.primary,
                                // borderColor: "red",
                                _active: {
                                    color: mainColor,
                                    borderColor: mainColor,
                                },
                                _focus: {
                                    color: mainColor,
                                    borderColor: mainColor,
                                },
                                _hover: {
                                    color: mainDarkColor,
                                    // borderColor: "red",
                                },

                            }
                        }
                    }

                }
            }
        ],

    };
    return theme;
};