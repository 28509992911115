import { createContext } from 'react';

const userContext = createContext(
    {
        user: null,
        signOut: null,
        name: null,
        picture: null,
        subscription: null,
        isTrial: null,
        authStatus: "configuring"
    }
);

export { userContext };