import React from "react";
import PropTypes from "prop-types";
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

const YoutubeEmbed = ({ embedId = "", thumbnail = null, width = "280" }) => (
  <div className="video-responsive" style={{ aspectRatio: "16/9" }}>
    {/* <iframe
      width={width}
      height="100%"
      src={`https://www.youtube.com/embed/${embedId}`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
      style={{ border: "none" }}
    /> */}
    <LiteYouTubeEmbed
      id={embedId}
      title="Embedded youtube"
      noCookie={true}
      poster={"maxresdefault"}
      thumbnail={thumbnail}
    />
  </div>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed;