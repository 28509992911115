import { Config } from "services/Config";
import { post } from "../Helper";


const report = {
    "total_tokens": 705,
    "response": "a) Overview: The comments convey a mix of appreciation for the camera work, frustration with crowded lineups and surf etiquette issues, and a comparison of the scene to nature's survival tactics.\n\nb) Significant Comment: The comment referring to the lack of surf etiquette being taught by some surf schools is important as it identifies a potential root cause of the issues observed in the footage, highlighting the need for education on surfing ethics.\n\nc) Suggestions for Improvement:\n1. Educating surfers: Many comments advocate for teaching proper surf etiquette to prevent issues like drop-ins and crowded lineups, suggesting that awareness and education can enhance the surfing experience for everyone involved.\n  \n2. Promoting respect: Several comments touch on the importance of respecting others in the water, emphasizing the need to address disrespectful behavior such as repeatedly dropping in on fellow surfers.\n   \n3. Finding less crowded spots: Recommendations to seek out less crowded surf spots or adjust surfing times to avoid peak hours could help alleviate overcrowding issues and improve the overall surfing experience for enthusiasts.",
    "resourceId": "njCrxFgFO-A"
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export class ReportService {

    static getReport = (resourceId, channelId, request = null, maxComments = 25) => {
        let param = {};
        if (request) {
            param.request = request;
        }
        if (channelId) {
            param.channelId = channelId;
        }
        if (maxComments) {
            param.maxComments = maxComments
        }

        return post(Config.getReportUrl() + "/" + resourceId, param)
        // report.resourceId = resourceId;
        // return sleep(2000).then(() => report);
    };
};
