import React, { useContext, useEffect, useState } from 'react';

import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Box, Container } from '@mui/material';

import { useTheme } from '@mui/material/styles';

import { ThemeProvider } from '@aws-amplify/ui-react';
import { getTheme } from './theme';
import { Amplify } from 'aws-amplify';
import { getAuthConfig } from './amplifyConfig';
import { useNavigate } from 'react-router-dom';
import { userContext } from 'Components/User';
import { useTranslation } from 'react-i18next';

import { I18n } from 'aws-amplify/utils';
import { translations } from '@aws-amplify/ui-react';
import ruVocabulary from './vocabulary_ru';
I18n.putVocabularies(translations);
I18n.putVocabularies({
  ru: ruVocabulary,
});

Amplify.configure(getAuthConfig());

const SignIn = () => {
  const mTheme = useTheme();
  const [customTheme, setCustomTheme] = useState(null);
  const navigate = useNavigate();
  const uContext = useContext(userContext);
  // const [inProgress, setInProgress] = useState(null);
  
  const { route } = useAuthenticator(context => [context.route]);
  const { i18n } = useTranslation();
  const [ currentLanguage, setCurrentLanguage ] = useState(i18n.language || "en")

  useEffect(() => {
    if (!customTheme) {
      setCustomTheme(getTheme(mTheme));
    }
  }, []);

  useEffect(() => {
    setCurrentLanguage(i18n.language)
    I18n.setLanguage(i18n.language);
  }, [i18n.language]);


  useEffect(() => {
    if (uContext?.user && route === "authenticated") {
      navigate(`/${i18n.language}/search`);
    }
  }, [uContext, route]);

  return (
    <Container
      fixed
      sx={{
        display: "flex",
        justifyContent: "center",
        flexGrow: 1,
        alignContent: "center",
        p: 0
      }}>
      <ThemeProvider theme={customTheme} colorMode={mTheme?.palette.mode}>
        <Box sx={{ display: "flex", height: "100%" }} id={(i18n.language || 'en') + "holder"}>
          {/* {inProgress && <LinearProgress sx={{ width: "400px" }} />} */}
          {
            i18n.language === currentLanguage  // rerender Authenticator after language has been changed
            && (
              <Authenticator
                className='signInButton'
                loginMechanisms={['email']}
                socialProviders={['google']}
              >
                {/* should be redirecting to the search page via userContext */}
              </Authenticator>
            )
          }
        </Box>
      </ThemeProvider>
    </Container>
  );
};

export default SignIn;