import { Badge, Box, Button, Card, CardActions, CardContent, Container, LinearProgress, Table, TableBody, TableCell, TableRow, Typography, tableCellClasses, useTheme } from "@mui/material";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PaymentService from "services/Payment";
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LaunchIcon from '@mui/icons-material/Launch';
import { useTranslation } from "react-i18next";
import utils from "utils";

const { useState, useEffect } = require("react");


const CheckoutReturn = () => {

  const [receipt, setReceipt] = useState(null);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const sessionId = searchParams.get("session_id");
    if (!sessionId) {
      console.error("CheckoutReturn: no session_id");
      return;
    }
    PaymentService.getReceipt(sessionId).then((receipt) => {
      console.log("PaymentService receipt: ", receipt);
      if (receipt.session_status !== "complete") {
        // oups...
        console.error("CheckoutReturn: payment didn't get through: ", receipt);
        navigate(`/${i18n.language}/checkout?retry=true`);
      } else {
        setReceipt(receipt);
      }
    });
  }, []);

  const payment_received_panel = () => (
    <Card>
      <CardContent >
        <Box display={"flex"} justifyContent={"center"} mt={".5em"}>
          <Badge
            badgeContent={
              <CheckCircleIcon
                fontSize="small"
                color="success"
                sx={{
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: "50%"
                }}

              />
            }
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            overlap="circular"
          >
            <ReceiptOutlinedIcon fontSize="large" />
            {/* <ReceiptIcon fontSize="large" /> */}
          </Badge>
        </Box>

        <Typography variant="h5" m={"1em"}>
          {t("checkout.thankYou")}
        </Typography>

        <Table aria-label="simple table"
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none"
            },
          }}
          size="small"
        >
          <TableBody>
            <TableRow >
              <TableCell >{t("checkout.name")}</TableCell>
              <TableCell >{receipt.customer_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell >{t("checkout.email")}</TableCell>
              <TableCell >{receipt.receipt_email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell >{t("checkout.orderTotal")}</TableCell>
              <TableCell >{utils.formatCurrency(receipt.currency, receipt.amount_total)}</TableCell>
            </TableRow>
            {receipt.receipt_number ? (
              <TableRow>
                <TableCell >{t("checkout.receiptNumber")}</TableCell>
                <TableCell >{receipt.receipt_number}</TableCell>
              </TableRow>
            ) : null}
            <TableRow>
              <TableCell >{t("checkout.paid")}</TableCell>
              <TableCell >{new Date(receipt.created * 1000).toLocaleString()}</TableCell>
            </TableRow>
          </TableBody>

        </Table>
      </CardContent>
      <CardActions sx={{ justifyContent: "space-between", mr: ".5em", ml: ".5em" }}>
        <Button
          href={receipt.receipt_url + "&locale=" + i18n.language}
          target="_blank"
          endIcon={<LaunchIcon />}
        >
          {t("checkout.viewReceipt")}
        </Button>
        <Button href={`/${i18n.language}/search`}>
          {t("checkout.close")}
        </Button>
      </CardActions>
    </Card >
  )
  return (
    <Container key="check_return_container" id="checkoutReturn"
      sx={{
        mt: "1em",
        mb: "1em",
        flexGrow: 1,
        justifyContent: "center"
      }}>
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        flexFlow: 1,
        height: "100%",
        alignItems: "center"
      }}>
        {!!receipt
          ? payment_received_panel()
          : <Box minWidth={"250px"} display={"flex"} justifyContent={"center"} flexDirection={"column"}>
            <Typography mb="1em" textAlign={"center"}>{t("checkout.processing")}</Typography>
            <LinearProgress />
          </Box>
        }
      </Box>
    </Container>
  )
}

export default CheckoutReturn;

