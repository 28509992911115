import { Button, Container, IconButton, InputAdornment, Stack, TextField, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Unstable_Grid2';
import SearchIcon from '@mui/icons-material/Search';
import "./index.css"
import { useNavigate, useSearchParams } from "react-router-dom";
import MediaPanel from "Components/MediaPanel";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useAuthenticator } from "@aws-amplify/ui-react";

const Search = () => {
  const { t, i18n } = useTranslation()
  const [textAreaSearch, setTextAreaSearch] = useState("");
  const [error, setError] = useState(null);
  const [inProgress, setInProgress] = useState(null);
  const navigate = useNavigate();
  const [searching, setSearching] = useState(false);
  const [searchStr, setSearchStr] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useAuthenticator((context) => [context.user]);
  const [navigateToSignOut, setNavigateToSignOut] = useState(false);

  useEffect(() => {
    if (navigateToSignOut) {
      setTimeout(() => {
        if (!user) {
          navigate(`/signin`);
        }
      }, 3333);
    }
  }, [navigateToSignOut]);

  useEffect(() => {
    const newSearch = searchParams.get("searchQuery");
    setTextAreaSearch(newSearch || "");
    if (!user) {
      setSearchStr(null);
      setSearching(true);
      setNavigateToSignOut(true);
      return;
    }
    if (!!newSearch) {
      if (searchStr !== newSearch) {
        setSearchStr(newSearch);
      }
      setSearching(true);
    } else {
      setSearchStr(null);
      setSearching(false);
    }
  }, [searchParams, user]);

  const errorMap = {
    "empty": t("search.enterNonEmptyValue"),
    "notFound": t("search.resourseNotFound")
  };

  const search = () => {
    navigate(`/${i18n.language}/search?searchQuery=${textAreaSearch}`)
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      search();
    }
  };

  const getButtonPanel = () => (
    <Grid container display="flex" justifyContent="center" alignItems="baseline" sx={{ width: "100%" }} id="mainSearchGrid">
      {/* xs={12} sm={9} md={7} lg={6} xl={5} */}
      <Grid sm={searching ? 9 : 7} >
        <Container>
          <Grid container display="flex" justifyContent="center" alignItems="baseline" rowSpacing={2} spacing={1}>
            <Grid xs="auto" sm={searching ? 9 : 12} display="flex" justifyContent="center" alignItems="baseline" width={"100%"}>
              <TextField
                inputRef={(input) => {
                  if (input && !searchStr) {
                    input.focus();
                  }
                }}
                fullWidth
                disabled={inProgress}
                error={!!error}
                helperText={error ? errorMap[error] : null}
                sx={{
                  minWidth: "300px",
                  maxWidth: "500px",
                  "& .MuiOutlinedInput-input:-webkit-autofill": {
                    margin: ".516em .516em .516em 0",
                    padding: ".516em .516em .516em 0"
                  }
                }}
                id="input-with-icon-textfield"
                label={t("search.enterLink")}
                placeholder={t("search.placeholder")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ opacity: 0.7 }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    !!textAreaSearch && <InputAdornment position="end">
                      <Tooltip title={t("search.clear")}>
                        <IconButton sx={{ padding: 0, opacity: 0.7 }} onClick={() => setTextAreaSearch("")}>
                          <CloseRoundedIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  )
                }}
                value={textAreaSearch}
                onChange={(event) => {
                  setError(false);
                  setTextAreaSearch(event.target.value);
                }}
                onKeyUp={handleKeyPress}
              />
            </Grid>
            <Grid xs={"auto"} sm={searching ? 3 : 12} maxWidth="500px" display="flex" justifyContent="center" flexGrow={1}>
              <Button
                size="large"
                className="searchButton"
                color="primary"
                variant="outlined"
                sx={{ fontWeight: "bold", width: "100%" }}
                onClick={search}
                disabled={inProgress}
              >
                {t("search.searchButton")}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  )

  return (
    <Stack
      direction="column"
      spacing={{ sm: 4 }}
      mt={{ sm: "1.5em", xs: "1em" }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: "center",
        flexGrow: 1,
        marginBottom: "1em"
      }}
    >
      {getButtonPanel()}

      <Container sx={{ paddingTop: "2em", display: "flex", flexGrow: (searching ? 1 : 0) }} >
        {searching && <MediaPanel querySearch={searchStr} setInProgress={setInProgress} />}
      </Container>
    </Stack>
  )
};

export default Search;