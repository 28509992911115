import { Box, Button, CircularProgress } from "@mui/material";
import React from "react";

const LoadingButton = ({
    text,
    onClick,
    inProgress,
    buttonSx = {},
    boxSx = {},
    buttonVariant = "outlined",
    buttonFullWidth = false,
}) => {

    return (
        <Box sx={{
            ...{ position: 'relative', display: "flex", justifyContent: "center" },
            ...boxSx
        }}>
            <Button
                fullWidth={buttonFullWidth}
                sx={buttonSx}
                variant={buttonVariant}
                disabled={inProgress}
                onClick={onClick}
            >
                {text}
            </Button>
            {inProgress && (
                <CircularProgress
                    size={24}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                    }}
                />
            )}
        </Box>
    );
}

export default LoadingButton;