
import i18n from "i18next";

const CSL_EMAIL = "CommonSenseLabs@gmail.com";

const formatCurrency = (currency, amount) => {
  return Intl.NumberFormat(
    i18n.language,
    {
      style: "currency",
      currency: currency
    }
  ).format(amount / 100);
};

const addLngPath = (name) => {
  const path = window.location.pathname;
  return (path.split("/")[1] || 'en') + name; // Default to English if no path
};

const toLocaleDateString = (date) => {
  return new Date(date).toLocaleDateString()
}

const utils = {
  formatCurrency,
  addLngPath,
  toLocaleDateString,
  CSL_EMAIL
}


export default utils;