import { Config } from "services/Config";


export const getAuthConfig = () => {
    return {
        Auth: {
            Cognito: {
                // region: "us-east-2",
                //  Amazon Cognito User Pool ID
                userPoolId: Config.getUserPoolId(),
                // Amazon Cognito Web Client ID (26-char alphanumeric string)
                userPoolClientId: Config.getUserPoolClientId(),
                signUpVerificationMethod: 'code',
                loginWith: {
                    email: true,
                    // OPTIONAL - Hosted UI configuration
                    oauth: {
                        domain: Config.getOauthDomin(),
                        scopes: [
                            'email',
                            'openid',
                            'profile',
                            'aws.cognito.signin.user.admin'
                        ],
                        // for federated login:
                        redirectSignIn: [Config.getRedirectSignIn()],
                        redirectSignOut: [Config.getRedirectSignOut()],
                        responseType: 'code',
                        providers: ['Google'],
                    }
                },
            }
        }
    };
};
